"use client";

import cx from "clsx";
import { useState } from "react";
import Button from "~/components/common/Button";
import Container from "~/components/common/Container";
import Link from "~/components/common/Link";
import { CAREERS_URL } from "~/constants/urls";
import SectionTitle from "../SectionTitle";
import { TeamMember } from "./TeamMember";

interface TeamProps {
  className?: string;
  description?: string;
  title?: string;
}

const DEFAULT_DESCRIPTION = "We've built many products, for small and large companies.";
const DEFAULT_TITLE = "Meet the Team";

const Team = ({
  className,
  description = DEFAULT_DESCRIPTION,
  title = DEFAULT_TITLE,
}: TeamProps) => {
  const [showAll, setShowAll] = useState(false);

  const hiringCard = (
    <TeamMember
      caption="Hiring"
      description={
        <>
          We're always looking for talented developers, designers, devops, and passionate people.
          Learn more on our{" "}
          <Link className="text-blue-500" href={CAREERS_URL}>
            careers page.
          </Link>
        </>
      }
      name="Maybe you?"
    />
  );

  return (
    <div className={cx("border-b bg-gray-100 dark:bg-transparent", className)}>
      <Container>
        <SectionTitle className="mx-auto mb-12 max-w-2xl" subtitle={description}>
          {title}
        </SectionTitle>
        <ul className={cx("-m-4 lg:flex lg:flex-wrap lg:justify-center", "max-w-5xl lg:mx-auto")}>
          <TeamMember
            caption="Co-Founder — Full Stack"
            coverImage="/img/authors/paul-cover@2x.jpg"
            description="Paul is a passionate engineer who knows what it takes to run an online business. As an entrepreneur and CTO, he founded a tech startup, managed engineers, and built complex products. Paul has worked with Mozilla, Credit Karma, Humble Bundle (YC W2010), and Minerva Project. He also enjoys kiteboarding, traveling, and solving tough problems."
            featured
            gitHubUrl="https://github.com/pcraciunoiu"
            linkedInUrl="https://www.linkedin.com/in/paulcraciunoiu"
            name="Paul Craciunoiu"
            profileImage="https://www.gravatar.com/avatar/8cac07b53b4220ede6e26a602d8b10c3?s=200"
          />
          <TeamMember
            caption="Co-Founder — Frontend & UX"
            coverImage="/img/authors/marius-cover@2x.jpg"
            description="Marius has a knack for beautiful designs and writing maintainable complex apps. With a history in user experience design, this frontend developer knows what great apps look like. He has worked with Credit Karma, FlightCar (YC W2013), and other startups. When he's not coding, Marius enjoys time with his family, coffee, rock climbing, and mountain biking."
            featured
            gitHubUrl="https://github.com/mariusc23"
            linkedInUrl="https://www.linkedin.com/in/mariusc23"
            name="Marius Craciunoiu"
            profileImage="https://www.gravatar.com/avatar/d9d1907dd837a8933c534f6bd770b9e1?s=200"
          />
        </ul>
        <div className="mx-auto my-12 w-48 rounded-full border-t-4" />
        <ul className="-m-4 lg:flex lg:flex-wrap lg:justify-center">
          <TeamMember
            caption="BizOps & HR"
            description="Keeping the biz running smoothly and the team growing, Kristin has a background in banking & finance, an MBA, and a talent for attention to detail."
            name="Kristin Yoder"
          />
          <TeamMember
            caption="UX & Visual Design"
            description="Mary Anne is a talented designer with a passion for clean and functional design. She delivers quality, high-fidelity mockups with ease."
            name="Mary Anne Rodis"
          />
          <TeamMember
            caption="Full Stack"
            description="Experienced at building products of all shapes and sizes, Camron has lots of tools up his sleeve to amp up your product."
            name="Camron Flanders"
          />
          <TeamMember
            caption="People Person"
            description="With decades of experience from growing a fintech business in Europe, Mats is the go to person for finding talent and building great teams."
            name="Mats Andersson"
          />
          <TeamMember
            caption="Full Stack"
            description="With a sharp mind for gathering requirements and finding edge cases, Graham understands the tradeoffs of building complex products with constraints."
            name="Graham Hughes"
          />
          {!showAll && hiringCard}
          {showAll && (
            <>
              <TeamMember
                caption="Full Stack"
                description="Mihai is ambitious and a quick learner. A strong team leader who can make product recommendations and put them into practice."
                name="Mihai Anton"
              />
              <TeamMember
                caption="Full Stack"
                description="With over 7 years experience in JavaScript alone, Vlad also teaches and mentors other developers, and has worked with multiple frameworks."
                name="Vlad Vedinas"
              />
              <TeamMember
                caption="Marketing"
                description="With contagious enthusiasm, Cecilia brings rapid iteration into content and social media marketing. She writes copy with business values front and center."
                name="Cecilia Hallgren"
              />
              <TeamMember
                caption="Infra & DevOps"
                description="Rasto has been programming since Linux was in diapers, and has extensive experience with AWS and automating cloud infrastructure."
                name="Rastislav Macko"
              />
              <TeamMember
                caption="Infra & DevOps"
                description="Carlos has more than 10 years of experience in DevOps, and helped us modernize infrastructure, improve performance, and reduce costs."
                name="Carlos Cuevas"
              />
              <TeamMember
                caption="Frontend"
                description="Having a breadth of experience launching his own startup, Mifi rapidly implements UIs even when requirements are broad, and with little guidance."
                name="Ionut Miftode"
              />
              {hiringCard}
            </>
          )}
        </ul>
        {!showAll && (
          <div className="mt-8 flex justify-center">
            <Button color="gray" onClick={() => setShowAll(true)}>
              Load More
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Team;
