import cx from "clsx";
import AnchorButton from "~/components/common/Button/AnchorButton";
import Heading from "~/components/common/Heading";
import Image, { ImageProps } from "~/components/common/Image";
import Paragraph from "~/components/common/Paragraph";
import SpaceBetween from "~/components/common/SpaceBetween";
import { FadeInView } from "~/components/common/Transitions";

const styles = {
  teamMemberCoverImage: cx(
    "inset-0 size-full scale-110 rounded-t object-cover blur-sm brightness-50 transition-all duration-500 group-hover:scale-100 group-hover:blur-0 group-hover:brightness-100"
  ),
};

interface TeamMemberProps {
  caption: string;
  coverImage?: ImageProps["src"] | null;
  description: React.ReactNode;
  featured?: boolean;
  gitHubUrl?: string;
  linkedInUrl?: string;
  name: string;
  profileImage?: ImageProps["src"] | null;
}

export function TeamMember({
  caption,
  coverImage,
  description,
  featured,
  gitHubUrl,
  linkedInUrl,
  name,
  profileImage,
}: TeamMemberProps) {
  return (
    <FadeInView>
      <li
        className={cx("shrink-0 p-4", featured ? "lg:w-1/2" : "lg:w-1/3")}
        data-testid="team-member"
      >
        <div className="group h-full rounded border bg-white shadow-sm dark:up-bg-dark">
          {profileImage && (
            <div className="relative overflow-hidden rounded-t border-b bg-gray-200 py-6">
              {coverImage && (
                <div className="absolute inset-0">
                  {typeof coverImage === "string" ? (
                    <img alt="" className={styles.teamMemberCoverImage} src={coverImage} />
                  ) : (
                    <Image alt="" className={styles.teamMemberCoverImage} src={coverImage} />
                  )}
                </div>
              )}
              <div className="relative z-10 mx-auto block size-24 rounded-full border-4 border-white bg-white shadow">
                <Image
                  alt={name}
                  className="rounded-full"
                  height={100}
                  src={profileImage}
                  width={100}
                />
              </div>
            </div>
          )}
          <div className="p-8">
            <Heading className="mb-1 text-center text-xl leading-snug" tag="h3">
              {name}
            </Heading>
            <Paragraph className="text-center text-gray-600">{caption}</Paragraph>
            <Paragraph className="mt-4">{description}</Paragraph>
            {(gitHubUrl || linkedInUrl) && (
              <div className="mt-6">
                <SpaceBetween spacing="mr-4">
                  {gitHubUrl && (
                    <AnchorButton
                      color="gray"
                      href={gitHubUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      GitHub
                    </AnchorButton>
                  )}
                  {linkedInUrl && (
                    <AnchorButton
                      color="gray"
                      href={linkedInUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      LinkedIn
                    </AnchorButton>
                  )}
                </SpaceBetween>
              </div>
            )}
          </div>
        </div>
      </li>
    </FadeInView>
  );
}
