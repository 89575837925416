import cx from "clsx";
import Heading from "~/components/common/Heading";
import Paragraph from "~/components/common/Paragraph";

interface SectionTitleProps {
  className?: string;
  subtitle?: string;
  subtitleClassName?: string;
  titleClassName?: string;
}

const SectionTitle = ({
  children,
  className,
  subtitle,
  subtitleClassName,
  titleClassName,
}: React.PropsWithChildren<SectionTitleProps>) => {
  return (
    <div className={className}>
      <Heading className={cx("-mt-4 text-center text-4xl leading-snug", titleClassName)} tag="h2">
        {children}
      </Heading>
      {subtitle && (
        <Paragraph
          className={cx(
            "mt-3 text-center font-serif text-lg leading-snug text-gray-600",
            subtitleClassName
          )}
        >
          {subtitle}
        </Paragraph>
      )}
    </div>
  );
};

export default SectionTitle;
